import { FC, useState, createContext } from 'react';
import HeaderVariant from "../enums/HeaderVariant";
type HeaderContext = {
    variant:HeaderVariant, setHeaderVariant: (variant: HeaderVariant) => void,
    label: string, setHeaderLabel: (label:string) => void,
    actionLeft: () => void, setHeaderActionLeft: (actionLeft: () => void) => void,
    actionRight: () => void, setHeaderActionRight: (actionRight: () => void) => void};

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const HeaderContext = createContext<HeaderContext>(
    {} as HeaderContext
);

export const HeaderProvider: FC = ({ children }) => {
    const [variant, setVariant] = useState<HeaderVariant>(HeaderVariant.Base);
    const [label, setLabel] = useState<string>();
    const [actionLeft, setActionLeft] = useState<() => void>(null);
    const [actionRight, setActionRight] = useState<() => void>(null);

    const setHeaderVariant = (variant:HeaderVariant) => {
        setVariant(variant);
    }

    const setHeaderLabel = (label:string) => {
        setLabel(label);
    }

    const setHeaderActionLeft = (actionLeft:() => void) => {
        setActionLeft(actionLeft);
    }

    const setHeaderActionRight = (actionRight:() => void) => {
        setActionRight(actionRight);
    }

    return (
        <HeaderContext.Provider value={{ variant, setHeaderVariant,
        label, setHeaderLabel,
        actionLeft, setHeaderActionLeft,
        actionRight, setHeaderActionRight}}>
            {children}
        </HeaderContext.Provider>
    );
};
