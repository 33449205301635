import type { FC } from 'react';
import { Formik } from 'formik';
import {Link as RouterLink, useNavigate} from 'react-router-dom';

import {
  Box,
  Button,
  TextField,
  Typography,
  Link,
  CircularProgress, InputAdornment, SvgIcon
} from '@material-ui/core';
import useAuth from 'src/hooks/useAuth';
import useRefMounted from 'src/hooks/useRefMounted';
import {useCallback, useEffect, useState} from "react";
import {Close, VisibilityOffOutlined} from "@material-ui/icons";
import {VisibilityOutlined} from "@material-ui/icons";
import {IconButton} from "@material-ui/core";
import {ReactComponent as SupportIcon} from "../../../../assets/icons/support.svg";
import AuthErrorCode from "../../../../enums/AuthErrorCode";
import {validateString} from "../../../../utils/validateString";
import {validateEmail} from "../../../../utils/validateEmail";
import {SUPPORT_EMAIL_ADDRESS, SUPPORT_EMAIL_BODY, SUPPORT_EMAIL_SUBJECT} from "../../../../utils/constants";

interface LoginJWTProps {
  initialSearch?: {
    acc: string,
    pw: string
  }
}
const LoginJWT:FC<LoginJWTProps> = ({initialSearch = {acc: null, pw: null}}) => {
  const initialFormValues = {
    email: "",
    password: "",
    touchedFields: {
      email: false,
      password: false
    },
    submit: null
  }

  const auth = useAuth();
  const isMountedRef = useRefMounted();
  const navigate = useNavigate();

  const [formValues, setFormValues] = useState(initialFormValues);
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
  const [isFormValid, setIsFormValid] = useState<boolean>(false);
  const [showPassword, setShowPassword] = useState<boolean>(false);
  const [authErrorCode, setAuthErrorCode] = useState<AuthErrorCode>(AuthErrorCode.DEFAULT);

  const toggleShowPassword = () => {
    setShowPassword(!showPassword);
  }

  const getEmailHelperText = () => {
    if (authErrorCode === AuthErrorCode.INVALID) {
      return "L'email o la password specificata non è corretta."
    }

    if (formValues.touchedFields.email) {
      if (formValues.email.length > 0 && !validateEmail(formValues.email)) {
        return "Questo indirizzo email non è valido";
      }

      if (formValues.email.length === 0) {
        return "Questo campo non può essere vuoto";
      }
    }

    return null;
  }

  const resetEmail = () => {
    setFormValues({...formValues, email: ""})
  }

  const handleBlur = (event) => {
    const {name} = event.target;
    const touched = formValues.touchedFields;
    touched[name] = true;
    setFormValues({...formValues, touchedFields: touched});
  }

  const handleChange = (event) => {
    const {name, value} = event.target;
    setFormValues({...formValues, [name]: value});
    setAuthErrorCode(AuthErrorCode.DEFAULT);
  }

  const validateForm = useCallback(() => {
    const vals = formValues;

    if (!validateString(vals.password) || !validateEmail(vals.email)) {
      setIsFormValid(false);
      return;
    }

    setIsFormValid(true);
  }, [formValues])


  useEffect(() => {
    if (initialSearch.acc && initialSearch.pw) {
      setFormValues({...formValues, email: initialSearch.acc, password: initialSearch.pw});

      setTimeout(() => {
        document.getElementById("login-submit-btn").click();
      }, 100);
    }
  }, [isMountedRef]);

  useEffect(() => {
    validateForm();
  }, [formValues]);

  return (
    <Formik
      initialValues={initialFormValues}
      onSubmit={async (values, { setErrors, setStatus }): Promise<void> => {
        try {
          setStatus({success: false});
          setIsSubmitting(true);

          await auth.login(initialSearch.acc ? initialSearch.acc : formValues.email,
              initialSearch.pw ? initialSearch.pw : formValues.password,
              initialSearch.pw && initialSearch.pw.length > 0 ? 1 : 0);

          if (isMountedRef.current) {
            setStatus({ success: true });
            setIsSubmitting(false);
          }
        } catch (err) {
          console.error(err);
          if (isMountedRef.current) {
            setStatus({success: false});
            setErrors({submit: err.message});
            setIsSubmitting(false);
            if (auth.code !== -1) {
              setAuthErrorCode(auth.code);
              return;
            }

            setAuthErrorCode(AuthErrorCode.INVALID);
          }
        }
      }}
    >
      {({
        handleSubmit,
      }): JSX.Element => (
        <form noValidate onSubmit={handleSubmit}>
          <Box px={"15px"} pb={"50px"}>
          <TextField
              sx={{marginTop: "26px"}}
              error={(authErrorCode === AuthErrorCode.INVALID) || (formValues.touchedFields.email && (formValues.email.length === 0 || !validateEmail(formValues.email)))}
            fullWidth
            margin="normal"
              helperText={getEmailHelperText()}
            label={'Email'}
            name="email"
            onBlur={handleBlur}
            onChange={handleChange}
            type="email"
            value={formValues.email}
            variant="outlined"
              InputProps={{
                endAdornment: (<InputAdornment position={"end"}>
                  <IconButton edge={"end"} color={"primary"} onClick={() => resetEmail()}>
                    {<Close/>}
                  </IconButton>
                </InputAdornment>)
              }}
          />
          <TextField
              sx={{marginTop: "20px"}}
              error={(authErrorCode === AuthErrorCode.INVALID) || (formValues.touchedFields.password && formValues.password.length === 0)}
            fullWidth
            margin="normal"
            label={'Password'}
            name="password"
            onBlur={handleBlur}
            onChange={handleChange}
            type={showPassword ? "text" : "password"}
            value={formValues.password}
            variant="outlined"
            InputProps={{
              endAdornment: (<InputAdornment position={"end"}>
                <IconButton edge={"end"} color={"primary"} onClick={toggleShowPassword}>
                  {showPassword ? <VisibilityOffOutlined/> : <VisibilityOutlined/>}
                </IconButton>
              </InputAdornment>)
            }}
          />
          <Box pt={"10px"} display={"flex"} justifyContent={"flex-end"}>
            <Link underline={"always"} onClick={(e) => {e.preventDefault(); e.stopPropagation(); navigate("/account/reset-password")}}>
              <Typography
                  component="span"
                  variant="subtitle2"
                  color="#0427B8"
                  fontWeight="500"
                  fontSize="12px"
              >
                {'Hai dimenticato la password?'}
              </Typography>
            </Link>
          </Box>
          <Button
              id={"login-submit-btn"}
            sx={{ mt: "30px", mb: "30px", borderRadius: "14px" }}
            color="primary"
            startIcon={isSubmitting ? <CircularProgress size="1rem" /> : null}
            disabled={!isFormValid || isSubmitting}
            type="submit"
            fullWidth
            size="large"
            variant="contained"
          >
            {'Login'}
          </Button>
          </Box>
          <Box sx={{zIndex: 1000, position: "fixed", left: 0, bottom: 0, width: "100%", display: "flex", justifyContent: "center"}}>

          <Box sx={{background: "#ffffff", maxWidth: "530px"}} borderRadius={"6px 6px 0 0"} display={"flex"} width={"100%"} height={"100%"} maxHeight={"50px"} alignItems={"center"} justifyContent={"space-between"} border={"1px solid #B4BEEA"}>
            <Box borderRadius={"5px 0 0 0"} display={"flex"} justifyContent={"center"} alignItems={"center"} sx={{background: "#052CCC", height: "100%", width: "100%", maxWidth: "48px", maxHeight: "48px"}} >
              <SvgIcon component={SupportIcon} sx={{padding: "9px", fill: "none", width: "100%", height: "100%"}} viewBox={"0 0 30 30"}/>
            </Box>
            <Box width={"100%"} sx={{textAlign: "center"}}>
              <Typography
                  component="span"
                  variant="subtitle2"
                  color="#3A3665"
                  fontWeight="400"
                  fontSize="16px"
                  py={"14px"}
              >
                {'Problemi?'}
              </Typography>{" "}
              <Link sx={{cursor: "pointer"}} underline={"always"} onClick={(e) => {e.preventDefault(); window.open(`mailto:${SUPPORT_EMAIL_ADDRESS}?subject=${SUPPORT_EMAIL_SUBJECT}&body=${SUPPORT_EMAIL_BODY}`, "_self")}}>
                <Typography
                    component="span"
                    variant="subtitle2"
                    color="#0427B8"
                    fontWeight="700"
                    fontSize="16px"
                >
                  {'Contatta l\'assistenza'}
                </Typography>
              </Link>
            </Box>
          </Box>
          </Box>
        </form>
      )}
    </Formik>
  );
}

export default LoginJWT;
