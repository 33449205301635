import {useCallback, useContext, useEffect, useRef, useState} from 'react';
import useAuth from 'src/hooks/useAuth';
import { NavLink } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';

import {
  Avatar,
  Box,
  Button,
  Divider,
  Hidden,
  lighten,
  List,
  ListItem,
  ListItemText,
  Popover,
  Typography
} from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import InboxTwoToneIcon from '@material-ui/icons/InboxTwoTone';
import { experimentalStyled } from '@material-ui/core/styles';
import ExpandMoreTwoToneIcon from '@material-ui/icons/ExpandMoreTwoTone';
import AccountBoxTwoToneIcon from '@material-ui/icons/AccountBoxTwoTone';
import LockOpenTwoToneIcon from '@material-ui/icons/LockOpenTwoTone';
import AccountTreeTwoToneIcon from '@material-ui/icons/AccountTreeTwoTone';
import {ReactComponent as LogoutIcon} from "../../../../../assets/icons/menu/menu_logout.svg";
import {DrawerContext} from "../../../../../contexts/DrawerContext";
import {sendGetRequest} from "../../../../../utils/axios";
import type {StructureSetting} from "../../../../../models/structuresetting";
import useRefMounted from "../../../../../hooks/useRefMounted";

const UserBoxButton = experimentalStyled(Button)(
  ({ theme }) => `
        padding-left: ${theme.spacing(1)};
        padding-right: ${theme.spacing(1)};
`
);

const MenuUserBox = experimentalStyled(Box)(
  ({ theme }) => `
        background: ${theme.colors.alpha.black[5]};
        padding: ${theme.spacing(2)};
`
);

const UserBoxText = experimentalStyled(Box)(
  ({ theme }) => `
        border-top: 1px dashed #697ED5;
        margin: 0 15px;
        text-align: left;
        padding-left: ${theme.spacing(1)};
`
);

const UserBoxLabel = experimentalStyled(Typography)(
  ({ theme }) => `
  padding-top: 30px;
        font-weight: ${theme.typography.fontWeightBold};
        color: #09043E;
        font-size: 16px;
        display: block;
        text-align: center;
`
);

const UserBoxDescription = experimentalStyled(Typography)(
  ({ theme }) => `
        color: #6B688B;
        font-weight: 500;
        font-size: 12px;
        text-transform: uppercase;
        text-align: center;
`
);

function DrawerUserBox() {
  const isMountedRef = useRefMounted();
  const { drawerToggle, toggleDrawer } = useContext(DrawerContext);
  const [structureSettings, setStructureSettings] = useState<StructureSetting>(null);
  const closeDrawer = () => toggleDrawer();

  const getStructureSettings = useCallback(async () => {
    try {
      const response = await sendGetRequest<{ structureSettings: StructureSetting }>(`structure_props/by_id/${account.structureId}`);

      if (isMountedRef.current && response.structureSettings) {
        setStructureSettings(response.structureSettings);
      }
    } catch (err) {
      console.error(err);
    }
  }, [isMountedRef]);

  const { t }: { t: any } = useTranslation();

  const navigate = useNavigate();

  const { account, logout } = useAuth();

  const ref = useRef<any>(null);
  const [isOpen, setOpen] = useState<boolean>(false);

  const handleOpen = (): void => {
    setOpen(true);
  };

  const handleClose = (): void => {
    setOpen(false);
  };

  const handleLogout = async (): Promise<void> => {
    try {
      handleClose();
      await logout();
      closeDrawer();
      navigate('/account/login');
    } catch (err) {
      console.error(err);
    }
  };

  useEffect(() => {
    getStructureSettings();
  }, [isMountedRef])

  if (!account || !structureSettings) {
    return null;
  }

  return (
    <>
      <UserBoxText>
        <UserBoxLabel variant="body1">{account.firstName + " " + account.lastName}</UserBoxLabel>
        <UserBoxDescription variant="body2">
          {structureSettings.name}
        </UserBoxDescription>
      </UserBoxText>
      <Box sx={{ m: 1 }}>
        <Button color="primary" fullWidth onClick={handleLogout}>
          <LogoutIcon/>
          <Box sx={{ml: 1}}>
          {"Logout"}
          </Box>
        </Button>
      </Box>
      {/*<UserBoxButton color="secondary" ref={ref} onClick={handleOpen}>
        <Avatar variant="rounded" alt={user.name} src={user.avatar} />
        <Hidden mdDown>
          <UserBoxText>
            <UserBoxLabel variant="body1">{user.name}</UserBoxLabel>
            <UserBoxDescription variant="body2">
              {user.jobtitle}
            </UserBoxDescription>
          </UserBoxText>
        </Hidden>
        <Hidden smDown>
          <ExpandMoreTwoToneIcon sx={{ ml: 1 }} />
        </Hidden>
      </UserBoxButton>
      <Popover
        anchorEl={ref.current}
        onClose={handleClose}
        open={isOpen}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right'
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right'
        }}
      >
        <MenuUserBox sx={{ minWidth: 210 }} display="flex">
          <Avatar variant="rounded" alt={user.name} src={user.avatar} />
          <UserBoxText>
            <UserBoxLabel variant="body1">{user.name}</UserBoxLabel>
            <UserBoxDescription variant="body2">
              {user.jobtitle}
            </UserBoxDescription>
          </UserBoxText>
        </MenuUserBox>
        <Divider sx={{ mb: 0 }} />
        <List sx={{ p: 1 }} component="nav">
          <ListItem button to="/management/user/1" component={NavLink}>
            <AccountBoxTwoToneIcon fontSize="small" />
            <ListItemText primary={t('Profile')} />
          </ListItem>
          <ListItem
            button
            to="/applications/mailbox/inbox"
            component={NavLink}
          >
            <InboxTwoToneIcon fontSize="small" />
            <ListItemText primary={t('Inbox')} />
          </ListItem>
          <ListItem
            button
            to="/applications/projects-board"
            component={NavLink}
          >
            <AccountTreeTwoToneIcon fontSize="small" />
            <ListItemText primary={t('Projects')} />
          </ListItem>
        </List>
        <Divider />
        <Box sx={{ m: 1 }}>
          <Button color="primary" fullWidth onClick={handleLogout}>
            <LockOpenTwoToneIcon sx={{ mr: 1 }} />
            {t('Sign out')}
          </Button>
        </Box>
      </Popover>*/}
    </>
  );
}

export default DrawerUserBox;
