import { useRoutes } from 'react-router-dom';
import './App.css';
import routes from './router';
// import { SnackbarProvider } from 'notistack';
import AdapterDateFns from '@material-ui/lab/AdapterDateFns';
import LocalizationProvider from '@material-ui/lab/LocalizationProvider';
import useScrollTop from 'src/hooks/useScrollTop';
import useAuth from 'src/hooks/useAuth';
import itLocale from 'date-fns/locale/it';
import ThemeProvider from './theme/ThemeProvider';
import {CssBaseline} from '@material-ui/core';
import LoadingOverlay from "./components/LoadingOverlay";

const App = () => {

  const content = useRoutes(routes);
  const auth = useAuth();
  useScrollTop();

  return (
    <ThemeProvider>
      <LocalizationProvider dateAdapter={AdapterDateFns} locale={itLocale}>
        {/* <SnackbarProvider
          maxSnack={6}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right'
          }}
        > */}

        <CssBaseline />
          {auth.isInitialized ? content : <></>}

        {/* </SnackbarProvider> */}
      </LocalizationProvider>
    </ThemeProvider>
  );
}
export default App;
