import type {Account} from "../models/account";
import {sendPutRequest} from "./axios";

export const Preferences = {
    BEACH_CURRENT_TAB: "beach_current_tab_shown",
    BEACH_VIEW_ACTIVE_FILTER: "beach_active_filter",
    BOOKINGS_NAV_ACTIVE_DATE: "bookings_nav_active_date",
    CHECK_IN_CURRENT_TAB: "check_in_current_tab_shown",
    USERS_CURRENT_TAB: "users_current_tab_shown"
}

export function HasPreference(account:Account, preferenceId:string) {
    if (account.preferences[preferenceId]) {
        return true;
    }

    return false;
}

export function GetString(account:Account, preferenceId: string) {
    if (HasPreference(account, preferenceId)) {
        return account.preferences[preferenceId];
    }

    return "";
}

export function GetNumber(account: Account, preferenceId: string) {
    if (HasPreference(account, preferenceId)) {
        return parseInt(account.preferences[preferenceId]);
    }

    return 0;
}

export async function UpdatePreferences(account:Account) {
    try {
        const response = await sendPutRequest(`/single/settings/${account.id}`, account);

        if (response.result) {
            console.log("preferenze aggiornate.");
            return true;
        }
    } catch (e) {
        console.log(e);
        return false;
    }

    return false;
}
