import { FC, ReactNode, useState, useContext } from 'react';
import { NavLink as RouterLink } from 'react-router-dom';
import clsx from 'clsx';
import { DrawerContext } from 'src/contexts/DrawerContext';

import PropTypes from 'prop-types';
import { Button, Badge, Collapse, ListItem } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import ExpandLessTwoToneIcon from '@material-ui/icons/ExpandLessTwoTone';
import ExpandMoreTwoToneIcon from '@material-ui/icons/ExpandMoreTwoTone';
import {Preferences} from "../../../../utils/preferences";
import {SUPPORT_EMAIL_ADDRESS, SUPPORT_EMAIL_BODY, SUPPORT_EMAIL_SUBJECT} from "../../../../utils/constants";

interface DrawerMenuItemProps {
  children?: ReactNode;
  link?: string;
    overrideRouter?: boolean;
    icon?: any;
  badge?: string;
  open?: boolean;
  active?: boolean;
  name: string;
}

const DrawerMenuItem: FC<DrawerMenuItemProps> = ({
  children,
  link,
  icon: Icon,
  badge,
  open: openParent,
  active,
  name,
    overrideRouter,
  ...rest
}) => {
  const [menuToggle, setMenuToggle] = useState<boolean>(openParent);
  const { t }: { t: any } = useTranslation();
  const { toggleDrawer } = useContext(DrawerContext);

  const toggleMenu = (): void => {
    setMenuToggle((Open) => !Open);
  };

  if (children) {
    return (
      <ListItem className="Mui-children" key={name} {...rest}>
        <Button
          className={clsx({ 'Mui-active': menuToggle })}
          startIcon={Icon && <Icon />}
          endIcon={
            menuToggle ? <ExpandLessTwoToneIcon /> : <ExpandMoreTwoToneIcon />
          }
          onClick={toggleMenu}
        >
          {t(name)}
        </Button>
        <Collapse in={menuToggle}>{children}</Collapse>
      </ListItem>
    );
  }

  if (overrideRouter) {
      return (<ListItem key={name} {...rest}>
          <Button
              onClick={(e) => {
                  e.preventDefault();
                  toggleDrawer();
                  window.open(link, "_self");
              }}
              startIcon={Icon && <Icon />}
          >
              {t(name)}
          </Button>
      </ListItem>
      )
  }

  return (
    <ListItem key={name} {...rest}>
      <Button
        activeClassName="Mui-active"
        component={RouterLink}
        onClick={(e) => {
          if (link !== "/management/bookings") {
            localStorage.removeItem(Preferences.BOOKINGS_NAV_ACTIVE_DATE);
          }

          toggleDrawer();
        }}
        to={link}
        startIcon={Icon && <Icon />}
      >
        {t(name)}
        {badge && <Badge badgeContent={badge} />}
      </Button>
    </ListItem>
  );
};

DrawerMenuItem.propTypes = {
  children: PropTypes.node,
  active: PropTypes.bool,
  link: PropTypes.string,
    overrideRouter: PropTypes.bool,
    icon: PropTypes.elementType,
  badge: PropTypes.string,
  open: PropTypes.bool,
  name: PropTypes.string.isRequired
};

DrawerMenuItem.defaultProps = {
  open: false,
  active: false,
    overrideRouter: false
};

export default DrawerMenuItem;
