import App from './App';
import ReactDOM from 'react-dom';
import 'src/utils/chart';
import * as serviceWorker from './serviceWorker';
import { HelmetProvider } from 'react-helmet-async';
import { BrowserRouter } from 'react-router-dom';

import 'nprogress/nprogress.css';
import { DrawerProvider } from './contexts/DrawerContext';
import { Provider } from 'react-redux';
import store from 'src/store';
import { AuthProvider } from './contexts/JWTAuthContext';
import {HeaderProvider} from "./contexts/HeaderContext";
import {LoadingOverlayProvider} from "./contexts/LoadingOverlayContext";

ReactDOM.render(
  <HelmetProvider>
    <Provider store={store}>
        <LoadingOverlayProvider>
            <DrawerProvider>
                <HeaderProvider>
                    <BrowserRouter>
                        <AuthProvider>
                            <App/>
                        </AuthProvider>
                    </BrowserRouter>
                </HeaderProvider>
            </DrawerProvider>
        </LoadingOverlayProvider>
    </Provider>
  </HelmetProvider>,
  document.getElementById('root')
);

serviceWorker.unregister();
