import {
  Box,
  Typography,
  Container,
} from '@material-ui/core';
import { Helmet } from 'react-helmet-async';
import useAuth from 'src/hooks/useAuth';
import JWTLogin from '../LoginJWT';

import { useTranslation } from 'react-i18next';
import { experimentalStyled } from '@material-ui/core/styles';
import {ReactComponent as LogoImage} from "../../../../../assets/images/logo_payoff.svg";

const MainContent = experimentalStyled(Box)(
    ({ theme }) => `
        flex: 1 1 auto;
        overflow: auto;
`
);

function LoginCover() {
  const { method } = useAuth() as any;
  const { t }: { t: any } = useTranslation();

  const {search} = window.location;

  const splitSearch = ():{acc, pw} => {
    let splitStr = search.replace("?", "");
    const splitted = splitStr.split("&");

    let arr = {
      acc: null,
      pw: null
    }

    for (const query of splitted) {
      let splitLocal = query.split("=");

      if (splitLocal[0] === "acc") {
        arr.acc = splitLocal[1];
        continue;
      }

      arr.pw = splitLocal[1];
    }

    return arr;
  }

  const initialSearch = splitSearch();

  return (
      <>
        <Helmet>
          <title>{"Accedi - Beachnow.it Manager"}</title>
        </Helmet>
          <MainContent id="root-container">
            <div style={{position: "absolute",
              height: "200px",
              width: "200px",
              top: "-8%",
              left: "-35%",
              background: "rgb(230, 233, 248)",
              borderRadius: "70px",
              transform: "rotate(55.1deg)",
            zIndex: -1}}/>
            <Container maxWidth="sm" sx={{px: "0"}} >
              <Box px={"15px"} display={"flex"} justifyContent={"center"} marginTop={"30px"}>
                <LogoImage height={100} width={250}/>
              </Box>
                <Box px={"15px"} textAlign="center" marginTop={"30px"}>
                  <Typography variant="h2" sx={{ mb: 1 }}>
                    {t('Benvenuto!')}
                  </Typography>
                  <Typography
                      variant="h4"
                      color="text.secondary"
                      fontWeight="normal"
                      sx={{ mt: 1, mb: 3 }}
                  >
                    {t('Inserisci le tue credenziali per accedere')}
                  </Typography>
                </Box>
                {method === 'JWT' && <JWTLogin initialSearch={initialSearch} />}
            </Container>
            {/*<div style={{position: "absolute",
              height: "200px",
              width: "200px",
              bottom: "-13%",
              left: "-41%",
              background: "rgb(230, 233, 248)",
              borderRadius: "70px",
              transform: "rotate(55.1deg)"}}/>*/}
          </MainContent>
      </>
  );
}

export default LoginCover;
