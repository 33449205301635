/* eslint-disable no-bitwise */
export const JWT_SECRET = "/rmrZzvWDT3qiw+1EPe0sqiwicexoXYThYMP7p2HTg1CRp1fN472auPPCwQ8Nlp0Ca19snfCXVlxJ8mBpgURoQMhgVbjzvrJlDtDRCBMsuTfzin34Vma59nVUCowMkzDzRpyeadhGMPua2KAOa/6rzC8/k/nFCzngPwCmuuEMjVmbG1cT6deG4K4dQRw6bXrzcDZKfrndac50aedWUj5azh+o1zibZ54TQP9Aws3rb+AmMnssDf3OhJGFF98OYKKmPhX1EKPWhxnZXmtmHAqewKSOuYUD3VHd1MENUDZKQbDXSUTbsFieeavK7VLSwwGBZtC2JslJ1CqR4WRL9pSxw==";
export const JWT_EXPIRES_IN = 3600 * 24 * 2;

export const sign = (
  payload: Record<string, any>,
  privateKey: string,
  header: Record<string, any>
) => {
  const now = new Date();
  header.expiresIn = new Date(now.getTime() + header.expiresIn);
  const encodedHeader = btoa(JSON.stringify(header));
  const encodedPayload = btoa(JSON.stringify(payload));
  const signature = btoa(
      Array
          .from(encodedPayload)
          .map((item, key) => (
              String.fromCharCode(item.charCodeAt(0) ^ JWT_SECRET[key % JWT_SECRET.length].charCodeAt(0))
          ))
          .join('')
  );

  return `${encodedHeader}.${encodedPayload}.${signature}`;
};

export const decode = (token: string): any => {
  const [encodedHeader, encodedPayload, signature] = token.split('.');
  const header = JSON.parse(atob(encodedHeader));
  const payload = JSON.parse(atob(encodedPayload));
  const now = new Date();

  if (now < header.expiresIn) {
    throw new Error('Expired token');
  }

  const verifiedSignature = btoa(
      Array
          .from(encodedPayload)
          .map((item, key) => (
              String.fromCharCode(item.charCodeAt(0) ^ JWT_SECRET[key % JWT_SECRET.length].charCodeAt(0))
          ))
          .join('')
  );

  if (verifiedSignature !== signature) {
    throw new Error('Invalid signature');
  }

  return payload;
};

export const verify = (
  token: string,
  privateKey: string
): Record<string, any> => {
  const [encodedHeader, encodedPayload, signature] = token.split('.');
  const header = JSON.parse(atob(encodedHeader));
  const payload = JSON.parse(atob(encodedPayload));
  const now = new Date();

  if (now < header.expiresIn) {
    throw new Error('The token is expired!');
  }

  const verifiedSignature = btoa(
      Array
          .from(encodedPayload)
          .map((item, key) => (
              String.fromCharCode(item.charCodeAt(0) ^ privateKey[key % privateKey.length].charCodeAt(0))
          ))
          .join('')
  );

  if (verifiedSignature !== signature) {
    throw new Error('The signature is invalid!');
  }

  return payload;
};
