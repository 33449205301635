import { FC, ReactNode, useState } from 'react';
import { Navigate, useLocation } from 'react-router-dom';
import PropTypes from 'prop-types';
import useAuth from 'src/hooks/useAuth';
import Login from 'src/content/pages/Auth/Login/Cover';
import AccountAccessArea from "../../enums/AccountAccessArea";
import isAccessPermitted from "../../utils/isAccessPermitted";

interface AccessVerificationProps {
    children: ReactNode;
    area: AccountAccessArea;
}

const AccessVerification: FC<AccessVerificationProps> = (props: { children: any, area: AccountAccessArea; }) => {
    const { area, children } = props;
    const auth = useAuth();
    const location = useLocation();
    const [requestedLocation, setRequestedLocation] = useState(null);

    if (!auth.isAuthenticated) {
        if (location.pathname !== requestedLocation) {
            setRequestedLocation(location.pathname);
        }
        return <Login />;
    }

    if (!isAccessPermitted(auth.account.permittedAreas, area)) {
        return <Navigate to={"/status/access-denied"} />
    }

    if (requestedLocation && location.pathname !== requestedLocation) {
        setRequestedLocation(null);
        return <Navigate to={requestedLocation} />;
    }

    return <>{children}</>;
};

AccessVerification.propTypes = {
    children: PropTypes.node
};

export default AccessVerification;
