import {FC, ReactNode, useCallback, useEffect} from 'react';
import { experimentalStyled } from '@material-ui/core/styles';
import { Box } from '@material-ui/core';
import { Outlet } from 'react-router-dom';

import DrawerIndex from './Drawer';
import Header from './Header';
import useAuth from "../../hooks/useAuth";
import {sendGetRequest, sendPutRequest} from "../../utils/axios";
import {Account} from "../../models/account";
import {format} from "date-fns";
import useRefMounted from "../../hooks/useRefMounted";

interface DrawerLayoutProps {
  children?: ReactNode;
}

const MainWrapper = experimentalStyled(Box)(
  ({ theme }) => `
        flex: 1 1 auto;
        display: flex;
        height: 100%;
        
        @media (min-width: ${theme.breakpoints.values.lg}px) {
            padding-left: ${theme.drawer.width};
        }
`
);

const MainContent = experimentalStyled(Box)(
  ({ theme }) => `
        margin-top: ${theme.header.height};
        flex: 1 1 auto;
        overflow: auto;
`
);

const DrawerLayout: FC<DrawerLayoutProps> = () => {
    const {account} = useAuth();
    const isMountedRef = useRefMounted();

    const setLastLogin = useCallback(async () => {
        try {
            if (account) {
                const response = await sendPutRequest(`staff/single/update_login/${account.id}`, {
                    lastLogin: new Date()
                });

                if (response.result) {
                    // Ok
                    console.log("saved");
                }
            }
        } catch (err) {
            console.error(err);
        }
    }, [account]);

    useEffect(() => {
        setLastLogin();
    }, [account]);

  return (
    <>
      <DrawerIndex />
      <MainWrapper>
        <Header />
        <MainContent id={"root-container"}>
          <Outlet />
        </MainContent>
      </MainWrapper>
    </>
  );
};

export default DrawerLayout;
