import { ListSubheader, List } from '@material-ui/core';
import { useLocation, matchPath } from 'react-router-dom';
import DrawerMenuItem from './item';
import {MenuItem, MenuItems} from './items';
import { experimentalStyled } from '@material-ui/core/styles';
import { useTranslation } from 'react-i18next';
import useAuth from "../../../../hooks/useAuth";
import {FC, useState} from "react";

const MenuWrapper = experimentalStyled(List)(
  ({ theme }) => `
    margin-bottom: ${theme.spacing(1)};
    padding: 0;
    z-index: 9;

    & > .MuiList-root {
      padding: 0 ${theme.spacing(2)} ${theme.spacing(2)};
    }

    .MuiListSubheader-root {
      text-transform: uppercase;
      font-size: ${theme.typography.pxToRem(13)};
      color: ${theme.drawer.menuItemHeadingColor};
      padding: ${theme.spacing(0.8, 2)};
      line-height: 1.4;
    }
`
);

const SubMenuWrapper = experimentalStyled(List)(
  ({ theme }) => `
    &.MuiList-root {
      padding: 0;

      .MuiList-root .MuiList-root .MuiListItem-root .MuiButton-root {
        font-weight: normal !important;
      }

      .MuiListItem-root {
        padding: 2px 15px;
    
        .MuiButton-root {
          display: flex;
          color: ${theme.drawer.menuItemColor};
          background-color: ${theme.drawer.menuItemBg};
          width: 100%;
          justify-content: flex-start;
          font-size: ${theme.typography.pxToRem(14)};
          font-weight: 400;
          padding: 8px 15px;
          position: relative;
          border-radius: 25px;

          .MuiBadge-root {
            position: relative;
            left: 20px;

            .MuiBadge-standard {
              background: #697ED5;
              font-size: ${theme.typography.pxToRem(12)};
              line-height: 26px;
              font-weight: 500;
              color: #ffffff;
            }
          }
    
          .MuiButton-startIcon,
          .MuiButton-endIcon {
            transition: ${theme.transitions.create(['color'])};

            .MuiSvgIcon-root {
              font-size: inherit;
              transition: none;
            }
          }

          .MuiButton-startIcon {
            font-size: ${theme.typography.pxToRem(26)};
            margin-right: ${theme.spacing(1.5)};
            color: ${theme.drawer.menuItemIconColor};
          }
          
          .MuiButton-endIcon {
            margin-left: auto;
            font-size: ${theme.typography.pxToRem(22)};
          }

          &.Mui-active {
            background-color: ${theme.drawer.menuItemBgActive};
            color: ${theme.drawer.menuItemColorActive};
            font-weight: 700;

            .MuiButton-startIcon,
            .MuiButton-endIcon {
                color: ${theme.drawer.menuItemIconColorActive};
            }
          }
        }

        &.Mui-children {
          flex-direction: column;
          line-height: 1;
        }

        .MuiCollapse-root {
          width: 100%;

          .MuiList-root {
            padding: ${theme.spacing(1, 0)};
          }

          .MuiListItem-root {
            padding: 1px ${theme.spacing(0)};

            .MuiButton-root {
              font-size: ${theme.typography.pxToRem(13)};
              padding: ${theme.spacing(0.5, 2, 0.5, 6.5)};

              &.Mui-active,
              &:hover {
                background-color: ${theme.drawer.menuItemBg};
              }
            }
          }
        }
      }
    }
`
);

const renderDrawerMenuItems = ({
  items,
  path,
}: {
  items: MenuItem[];
  path: string;
}): JSX.Element => (
  <SubMenuWrapper>
    {items.reduce((ev, item) => reduceChildRoutes({ ev, item, path}), [])}
  </SubMenuWrapper>
);

const reduceChildRoutes = ({
  ev,
  path,
  item
}: {
  ev: JSX.Element[];
  path: string;
  item: MenuItem;
}): Array<JSX.Element> => {
  const key = item.name;

  const exactMatch = item.link ? !!matchPath({
    path: item.link,
    end: true
  }, path) : false;

  if (item.items) {
    const partialMatch = item.link ? !!matchPath({
      path: item.link,
      end: false
    }, path) : false;

    ev.push(
      <DrawerMenuItem
        key={key}
        active={partialMatch}
        open={partialMatch}
        overrideRouter={item.overrideRouter}
        name={item.name}
        icon={item.icon}
        link={item.link}
        badge={item.badge || ""}
      >
        {renderDrawerMenuItems({
          path,
          items: item.items
        })}
      </DrawerMenuItem>
    );
  } else {
    ev.push(
      <DrawerMenuItem
        key={key}
        active={exactMatch}
        name={item.name}
        overrideRouter={item.overrideRouter}
        link={item.link}
        badge={item.badge || ""}
        icon={item.icon}
      />
    );
  }

  return ev;
}

interface DrawerMenuProps {
  menuItems: MenuItems[];
}

const DrawerMenu:FC<DrawerMenuProps> = ({menuItems}) => {
  const location = useLocation();
  const { t }: { t: any } = useTranslation();
  const {account} = useAuth();

  return (
    <>
      {menuItems.map((section, index) => {
        const local = (account && account.permittedAreas ? section.items.filter(item => (item.accessArea < 0 ? item : account.permittedAreas.includes(String(item.accessArea)))) : section.items);

        return (<MenuWrapper
            key={section.heading}
            sx={{display: (!local || local.length === 0 ? "none" : "initial")}}
            subheader={
              <ListSubheader sx={{
                marginLeft: "15px",
                marginRight: "15px",
                borderTop: (index === 0 ? "none" : "1px dashed #697ED5")
              }} disableSticky>{t(section.heading)}</ListSubheader>
            }
        >
          {renderDrawerMenuItems({
            items: local,
            path: location.pathname
          })}
        </MenuWrapper>)
      })}
    </>
  );
}

export default DrawerMenu;
