enum HeaderVariant {
    Base = 0,
    Back = 1,
    Back_Add = 2,
    Back_Delete = 3,
    Menu_Add = 4,
    Back_Block = 5,
    Menu_Info = 6
}

export default HeaderVariant;
