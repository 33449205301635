import {ReactNode} from 'react';
import AccountAccessArea from "../../../../enums/AccountAccessArea";
import {ReactComponent as HomeIcon} from "../../../../assets/icons/menu/menu_home.svg";
import {ReactComponent as AccountsIcon} from "../../../../assets/icons/menu/menu_accounts.svg";
import {ReactComponent as BeachIcon} from "../../../../assets/icons/menu/menu_beach.svg";
import {ReactComponent as BookingsIcon} from "../../../../assets/icons/menu/menu_bookings.svg";
import {ReactComponent as CheckInIcon} from "../../../../assets/icons/menu/menu_checkin.svg";
import {ReactComponent as CouponsIcon} from "../../../../assets/icons/menu/menu_coupons.svg";
import {ReactComponent as ProductsIcon} from "../../../../assets/icons/menu/menu_products.svg";
import {ReactComponent as SettingsIcon} from "../../../../assets/icons/menu/menu_settings.svg";
import {ReactComponent as SettingsAltIcon} from "../../../../assets/icons/menu/menu_settings_alt.svg";
import {ReactComponent as SupportIcon} from "../../../../assets/icons/menu/menu_support.svg";
import {ReactComponent as UsersIcon} from "../../../../assets/icons/menu/menu_users.svg";
import {ReactComponent as BookingStatisticsIcon} from "../../../../assets/icons/menu/menu_booking_statistics.svg";
import {SUPPORT_EMAIL_ADDRESS, SUPPORT_EMAIL_BODY, SUPPORT_EMAIL_SUBJECT} from "../../../../utils/constants";

export interface MenuItem {
  link?: string;
  icon?: ReactNode;
  badge?: string;
  overrideRouter?: boolean;
  items?: MenuItem[];
  name: string;
  accessArea: AccountAccessArea;
}

export interface MenuItems {
  items: MenuItem[];
  heading: string;
}

const menuItems: MenuItems[] = [
  {
    heading: '',
    items: [
      {
        accessArea: AccountAccessArea.DASHBOARD,
        name: 'Panoramica',
        icon: HomeIcon,
        link: '/dashboard/main'
      },
      {
        accessArea: AccountAccessArea.CHECK_IN_AND_BOOKINGS,
        name: 'Check-in',
        icon: CheckInIcon,
        link: '/management/checkins',
        badge: ""
      }
    ]
  },
  {
    heading: '',
    items: [
      {
        accessArea: AccountAccessArea.BEACH_AND_PRODUCTS,
        name: 'Spiaggia',
        icon: BeachIcon,
        link: '/management/beach'
      },
      {
        accessArea: AccountAccessArea.BEACH_AND_PRODUCTS,
        name: 'Tipologia postazioni',
        icon: ProductsIcon,
        link: '/management/products'
      },
      {
        accessArea: AccountAccessArea.COUPONS,
        name: 'Coupon',
        icon: CouponsIcon,
        link: '/management/coupons'
      }
    ]
  },
  {
    heading: '',
    items: [
      {
        accessArea: AccountAccessArea.CHECK_IN_AND_BOOKINGS,
        name: 'Prenotazioni',
        icon: BookingsIcon,
        link: '/management/bookings'
      },
      {
        accessArea: AccountAccessArea.BOOKING_STATISTICS,
        name: 'Andamento prenotazioni',
        icon: BookingStatisticsIcon,
        link: '/booking_trend/main'
      },
      {
        accessArea: AccountAccessArea.USERS,
        name: 'Utenti',
        icon: UsersIcon,
        link: '/management/users',
      },
      {
        accessArea: AccountAccessArea.STAFF,
        name: 'Staff',
        icon: AccountsIcon,
        link: '/management/staff'
      }
    ]
  },
  {
    heading: '',
    items:
    [
      {
        accessArea: AccountAccessArea.ACCOUNT_SETTINGS,
        name: 'Impostazioni account',
        icon: SettingsIcon,
        link: '/account/settings'
      },
      {
        accessArea: AccountAccessArea.STRUCTURE_SETTINGS,
        name: 'Impostazioni struttura',
        icon: SettingsAltIcon,
        link: '/structure/settings'
      }
      ,
      {
        accessArea: AccountAccessArea.SUPPORT,
        name: 'Assistenza',
        overrideRouter: true,
        icon: SupportIcon,
        link: `mailto:${SUPPORT_EMAIL_ADDRESS}?subject=${SUPPORT_EMAIL_SUBJECT}&body=${SUPPORT_EMAIL_BODY}`
      }
        ]
  }
];

export default menuItems;
