import {createContext, FC, ReactNode, useEffect, useReducer} from 'react';
import {Account} from "../models/account";
import axios, {sendGetRequest, sendPostRequest} from 'src/utils/axios';
import {decode, JWT_EXPIRES_IN, JWT_SECRET, sign, verify} from 'src/utils/jwt';
import PropTypes from 'prop-types';
import AuthErrorCode from "../enums/AuthErrorCode";

interface AuthState {
  isInitialized: boolean;
  isAuthenticated: boolean;
  account: Account | null;
  structureId: string | null;
  code: AuthErrorCode;
}

interface AuthContextValue extends AuthState {
  method: 'JWT';
  login: (email: string, password: string, fromAdmin:number) => Promise<void>;
  logout: () => void;
}

interface AuthProviderProps {
  children: ReactNode;
}

type InitializeAction = {
  type: 'INITIALIZE';
  payload: {
    isAuthenticated: boolean;
    account: Account | null;
    code: AuthErrorCode;
  };
};

type LoginAction = {
  type: 'LOGIN';
  payload: {
    isAuthenticated: boolean,
    account: Account,
    code: AuthErrorCode
  };
};

type LogoutAction = {
  type: 'LOGOUT';
};

type Action = InitializeAction | LoginAction | LogoutAction;

const initialAuthState: AuthState = {
  isAuthenticated: false,
  isInitialized: false,
  account: null,
  structureId: null,
  code: AuthErrorCode.DEFAULT
};

const setSession = (accessTkSignature: string | null, accessToken: string | null): void => {
  if (accessToken && accessTkSignature) {
    localStorage.setItem('accessToken', JSON.stringify({token: accessToken, signature: accessTkSignature}));
    axios.defaults.headers.common.Authorization = `Bearer ${accessToken}`;
  } else {
    localStorage.removeItem('accessToken');
    delete axios.defaults.headers.common.Authorization;
  }
};

const handlers: Record<string, (state: AuthState, action: Action) => AuthState> = {
  INITIALIZE: (state: AuthState, action: InitializeAction): AuthState => {
    const { isAuthenticated, account, code } = action.payload;

    return {
      ...state,
      isAuthenticated,
      isInitialized: true,
      account,
      structureId: (account ? account.structureId : "-1"),
      code: code
    };
  },
  LOGIN: (state: AuthState, action: LoginAction): AuthState => {
    const { account, code, isAuthenticated } = action.payload;

    return {
      ...state,
      isAuthenticated: isAuthenticated,
      account,
      structureId: (account ? account.structureId : "-1"),
      code: code
    };
  },
  LOGOUT: (state: AuthState): AuthState => ({
    ...state,
    isAuthenticated: false,
    account: null,
    structureId: null,
    code: AuthErrorCode.DEFAULT
  })
};

const reducer = (state: AuthState, action: Action): AuthState => (
    handlers[action.type] ? handlers[action.type](state, action) : state
);

const AuthContext = createContext<AuthContextValue>({
  ...initialAuthState,
  method: 'JWT',
  login: () => Promise.resolve(),
  logout: () => Promise.resolve(),
});

export const AuthProvider: FC<AuthProviderProps> = (props) => {
  const { children } = props;
  const [state, dispatch] = useReducer(reducer, initialAuthState);

  const {search} = window.location;

  const splitSearch = ():{acc, pw} => {
    let splitStr = search.replace("?", "");
    const splitted = splitStr.split("&");

    let arr = {
      acc: null,
      pw: null
    }

    for (const query of splitted) {
      let splitLocal = query.split("=");

      if (splitLocal[0] === "acc") {
        arr.acc = splitLocal[1];
        continue;
      }

      arr.pw = splitLocal[1];
    }

    return arr;
  }

  useEffect(() => {
    const initialize = async (): Promise<void> => {
      try {
        const initialSearch = splitSearch();

        if (initialSearch.acc && initialSearch.pw && localStorage.getItem('accessToken')) {
          window.localStorage.removeItem('accessToken');
          dispatch({
            type: 'INITIALIZE',
            payload: {
              isAuthenticated: false,
              account: null,
              code: AuthErrorCode.DEFAULT
            }
          });
          return;
        }

        const accessToken = window.localStorage.getItem('accessToken');

        const tkFromJson = JSON.parse(accessToken);

        if (accessToken && tkFromJson && verify(tkFromJson.signature, JWT_SECRET)) {
          setSession(tkFromJson.signature, tkFromJson.token);

          const accountId = decode(tkFromJson.signature).id;

          const response = await sendGetRequest(`staff/${accountId}`, {headers: {"x-access-token": tkFromJson.token}}) as Account;
          const {account, code} = response;

          dispatch({
            type: 'INITIALIZE',
            payload: {
              isAuthenticated: true,
              account,
              code: code
            }
          });
        } else {
          dispatch({
            type: 'INITIALIZE',
            payload: {
              isAuthenticated: false,
              account: null,
              code: AuthErrorCode.DEFAULT
            }
          });
        }
      } catch (err) {
        console.error(err);
        dispatch({
          type: 'INITIALIZE',
          payload: {
            isAuthenticated: false,
            account: null,
            code: AuthErrorCode.DEFAULT
          }
        });
      }
    };

    initialize();
  }, []);

  const login = async (email: string, password: string, fromAdmin:number = 0): Promise<void> => {
    const response = await sendPostRequest<{accessToken: string, account: Account, code: AuthErrorCode}>("auth/login", {
      email,
      password,
      fromAdmin
    })

    const { accessToken, account, code } = response;

    if (code === AuthErrorCode.INVALID) {
      dispatch({
        type: 'LOGIN',
        payload: {
          isAuthenticated: false,
          account: null,
          code: code
        }
      });
    }

    const accessTkSig = sign({id: account.id}, JWT_SECRET, {
      expiresIn: JWT_EXPIRES_IN
    });

    setSession(accessTkSig, accessToken);
    dispatch({
      type: 'LOGIN',
      payload: {
        isAuthenticated: true,
        account,
        code: code
      }
    });
  };

  const logout = async (): Promise<void> => {
    setSession(null, null);
    dispatch({ type: 'LOGOUT' });
  };

  return (
      <AuthContext.Provider
          value={{
            ...state,
            method: 'JWT',
            login,
            logout
          }}
      >
        {children}
      </AuthContext.Provider>
  );
};

AuthProvider.propTypes = {
  children: PropTypes.node.isRequired
};

export default AuthContext;
